// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBJi-AFRiJf0FeFhhsNF6csVWtRgU9wLAk",
  authDomain: "mascotamasapp-staging.firebaseapp.com",
  projectId: "mascotamasapp-staging",
  storageBucket: "mascotamasapp-staging.appspot.com",
  messagingSenderId: "865737329208",
  appId: "1:865737329208:web:17cac6eb29850495c2b4cd"
};

// Inicializar Firebase
const app = initializeApp(firebaseConfig);

// Obtener Firestore
const db = getFirestore(app);

export { db };